<template>
<div>
    <b-dropdown class="user-menu" right variant="link" v-if="isAuthenticated">
        <template v-slot:button-content>
            <div class="d-flex align-items-center">
                <b-avatar variant="dark" size="sm" class="mr-3" :src="user.profilePicture" v-if="user.profilePicture"></b-avatar>
                <b-button style="background-color: var(--color-500); border: 2px solid var(--color-500); width: 2.3rem !important; height: 2.3rem !important;" class="header-btn d-flex align-items-center justify-content-center" size="sm" variant="dark" pill v-else>
                    <!-- <span class="material-icons-outlined md-light" style="font-size: 1.25rem;">person</span> -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 18px;">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                </b-button>
                <!-- <div class="small ellipses d-none d-md-block" style="max-width: 120px;">{{ user.email }}</div> -->
            </div>
        </template>
        <b-dropdown-item to="/pro" v-if="!isPro">
			<div class="d-flex align-items-center justify-content-between">
                <div class="font-weight-bold">
                    Upgrade Now
                </div>
				<div class="material-icons-outlined notification-icon text-primary">bolt</div>
            </div>
        </b-dropdown-item>
        <b-dropdown-divider v-if="!isPro"></b-dropdown-divider>
        <!-- <b-dropdown-item href="https://docs.aminoeditor.com/getting-started" target="_blank">
            Getting Started
        </b-dropdown-item> -->
        <b-dropdown-item to="/notifications">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    Notifications
                </div>
				<div class="material-icons-outlined notification-icon text-primary" v-if="unreadNotifications > 0">notifications</div>
				<!-- <div class="material-icons-outlined notification-icon text-muted" v-else>notifications</div> -->
            </div>
        </b-dropdown-item>
        <b-dropdown-item to="/status">
			<div class="d-flex align-items-center justify-content-between">
                <div>
                    Status
                </div>
				<div class="material-icons-outlined notification-icon text-primary" v-if="status.indicator != 'none'">info</div>
				<!-- <div class="material-icons-outlined notification-icon text-muted" v-else>info</div> -->
            </div>
        </b-dropdown-item>
		<b-dropdown-divider></b-dropdown-divider>
		<b-dropdown-item to="/account">
            Account
        </b-dropdown-item>
        <b-dropdown-item href="https://docs.aminoeditor.com/" target="_blank">
            Docs
        </b-dropdown-item>
		<b-dropdown-item href="https://www.bonfire.com/store/amino" target="_blank">
            Store
        </b-dropdown-item>
        <b-dropdown-item  v-if="isPro" href="mailto:support@aminoeditor.com" target="_blank">
            Help
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <template v-if="isAdminUser">
            <b-dropdown-item to="/admin">
                Admin
            </b-dropdown-item>
        </template>
        <b-dropdown-item to="/sign-out">
            Sign Out
        </b-dropdown-item>
    </b-dropdown>
</div>
</template>

<script>
export default {
    name: 'UserMenu',
    data() {
        return {
			unreadNotifications: 0,
            status: {
                indicator: 'none'
            },
            statusInterval: false
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },
        user() {
            return this.$store.getters["auth/user"] || {};
        },
        isAdminUser() {
            return this.user.roles.includes("admin");
        },
		routeName () {
			return this.$route.name;
		},
		Notifications () {
			const { Notifications } = this.$FeathersVuex.api;
			return Notifications;
		},
		NotificationAggregate () {
			const { NotificationAggregate } = this.$FeathersVuex.api;
			return NotificationAggregate;
		},
        statusVariant() {
            if (this.status.indicator == 'maintenance') {
                return 'primary';
            }
            if (this.status.indicator != 'none') {
                return 'danger';
            }

            return '';
        }
    },
    methods: {
		resetUnreadNotifications () {
			this.unreadNotifications = 0;
		},
		countUnreadNotifications () {
			this.NotificationAggregate.find().then(notifications => {
				for (let notification of notifications) {
					if (!notification.readBy.length) {
						this.unreadNotifications++;
					}
				}
			}).catch(() => {});
		},
		incrementUnreadNotifications () {
			this.unreadNotifications++;
		},
		setUnreadNotifications () {
			this.resetUnreadNotifications();
			this.countUnreadNotifications();
		},
        loadStatus() {
            fetch('https://q9g778g71y2k.statuspage.io/api/v2/status.json').then(res => {
                return res.json();
            }).then(json => {
                for (let key in json.status) {
                    this.status[key] = json.status[key];
                }
            });
        }
    },
    mounted() {
		this.countUnreadNotifications();
		this.Notifications.on('created', this.incrementUnreadNotifications);
		this.Notifications.on('updated', this.setUnreadNotifications);
		this.Notifications.on('patched', this.setUnreadNotifications);
        this.loadStatus();
        this.statusInterval = setInterval(this.loadStatus, 30000);
    },
    unmounted() {
        clearInterval(this.statusInterval);
    },
	beforeDestroy () {
		this.Notifications.off('created', this.incrementUnreadNotifications);
		this.Notifications.off('updated', this.setUnreadNotifications);
		this.Notifications.off('patched', this.setUnreadNotifications);
	}
}
</script>

<style lang="scss">
.user-menu {
    button.btn.btn-link {
        padding: 0 !important;
    }
    button.dropdown-toggle {
        padding-top: 0;
        padding-bottom: 0;
    }
    .dropdown-toggle:after {
        display: none;
    }
}
</style>

<style lang="scss" scoped>
.notification-icon {
	font-size: 1rem !important;
}
</style>
